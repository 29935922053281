<template>
    <div class="content-page">
        <div class="content-top" >
            <div class="content-top" style="background-color: black;">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-10 col-md-8">
                            <ul class="d-flex nav nav-pills mb-4 text-center event-tab" id="event-pills-tab" role="tablist">
                                <li class="nav-item">
                                    <a id="view-btn" class="nav-link active show" data-toggle="pill" href="#event1"
                                        data-extra="#search-with-button" role="tab" aria-selected="true">Specialité</a>
                                </li>
                                <li class="nav-item">
                                    <a id="view-schedule" class="nav-link" data-toggle="pill" href="#event2"
                                        data-extra="#view-event" role="tab" aria-selected="false">Mon RDV</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center" v-if="load">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="container">
            <!-- <LoaderVue v-if="load" /> -->
            <div class="row">
                <div class="col-lg-12">
                    <div class="event-content">
                        <!--Entreprise Part Start-->
                        <div id="event1" class="tab-pane fade active show">
                            
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="event-content">
                                            <div class="row">
                                                <div class="col-lg-4 col-md-6" v-for="entreprise in listEntreprise.slice(0, 1)" :key="entreprise.id">
                                                    <div class="card card-block card-stretch card-height">
                                                        <div class="card-body rounded event-detail event-detail-info">
                                                            <div class="d-flex align-items-top justify-content-center">
                                                                <div>
                                                                    <h4 class="mb-2 mr-4 text-center">{{ entreprise.nom.toUpperCase() }}
                                                                    </h4>
                                                                    <p class="mb-7 text-center">{{ entreprise.lieu }}</p>
                                                                    <div class="d-flex align-items-center justify-content-center pt-7">
                                                                        <a 
                                                                            @click="updateTarget(entreprise.id, entreprise.nom)"
                                                                            class="btn btn-outline-info px-xl-5">Prendre un rdv
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" v-if="noEntreprise && !load">
                                                <div class="col-lg-4 col-md-6"></div>
                                                <div class="col-lg-4 col-md-6">
                                                    <div class="card card-block card-stretch card-height">
                                                        <div class="card-body rounded event-detail event-detail1">
                                                            <div class="text-center mb-4">
                                                                Pas d'entreprise dans ce secteur d'activité
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Entreprise Part End-->

                        <!--Mon RDV Start-->
                        <div id="event2" class="tab-pane fade active">
                            <div class="col-lg-12">
                                <div class="card card-block card-stretch">
                                    <div class="card-body">
                                        <div class="d-flex flex-wrap align-items-center justify-content-between">
                                            <h6 class="mb-3 mb-md-0">Code RDV</h6> 
                                            <input type="text" class="text search-input form-control api-password"
                                                placeholder="XXXX" v-model="codeRdvInput" />
                                            <div class="media flex-wrap api-link">
                                                <button class="btn btn-outline-primary px-xl-5"
                                                    data-input="#api-password" v-on:click="checkCode">OK</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12" v-for="rdv in dataRdv" :key="rdv.id">
                                    <div class="card card-block card-stretch">
                                        <div class="card-body">
                                            <div class="d-flex flex-wrap align-items-center justify-content-between">
                                                <div v-if="rdv && operation_found && entreprise_found" class="d-flex flex-wrap align-items-center">
                                                    <div class="date mr-3">
                                                        <h4 class="text-info">
                                                            {{ dateRefactor(rdv.date).jour }} -
                                                            {{ dateRefactor(rdv.date).mois }} -
                                                            <span style="color: black;">{{ dateRefactor(rdv.date).annee
                                                            }}</span>
                                                        </h4>
                                                    </div>
                                                    <div class="border-left pl-3">
                                                        <div class="media align-items-top">
                                                            <h5 class="mb-3">{{ rdv.nom }} {{ rdv.prenom }}</h5>
                                                            <span class="ml-5 mt-1 badge badge-light">
                                                                {{
                                                                    operation_found.libelle
                                                                }}
                                                            </span>
                                                            <span class="ml-2 mt-1 badge badge-danger"
                                                                v-if="rdvExpired(rdv.date.slice(0, 10)+' '+rdv.heure) == true && rdv.status == 0">Expiré</span>                                          
                                                            <span class="ml-2 mt-1 badge badge-primary"
                                                                v-else-if="rdv.status == 0">En attente</span>
                                                            <span class="ml-2 mt-1 badge badge-danger" v-else-if="rdv.status == 2">
                                                                Annulé</span>
                                                        </div>
                                                        <div class="media align-items-center">
                                                            <p class="mb-0 pr-3">{{ rdv.phone }}</p>
                                                            <p class="mb-0 pr-3">{{ rdv.email }}</p>
                                                        </div>
                                                        <div class="media align-items-center">
                                                            <p class="mb-0 pr-3"><i
                                                                    class="ri-time-line mr-2 text-info"></i>{{ rdv.heure
                                                                    }}</p>
                                                            <p class="mb-0"><i
                                                                    class="ri-map-pin-line mr-2 text-info"></i>
                                                                {{ entreprise_found.nom }}, {{ entreprise_found.lieu }}
                                                                {{ entreprise_found.adresse }} 
                                                            </p>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <div class="media flex-wrap api-link float-right" v-if="rdvExpired(rdv.date.slice(0, 10)+' '+rdv.heure) == false && rdv.status == 0">
                                                        <button class="btn btn-outline-primary px-xl-5"
                                                            data-input="#api-password" v-on:click="cancelRdv(rdv.id)">Annuler
                                                        </button>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="!isCorrectCode && !load">
                                <div class="col-lg-4 col-md-6"></div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="card card-block card-stretch card-height">
                                        <div class="card-body rounded event-detail event-detail1">
                                            <div class="d-flex justify-content-center mb-4">
                                                Pas de RDV de ce code
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Mon RDV End-->
                    </div>
                </div>
            </div>
        </div>
        
    </div>

    <!--Modal Create Evevent-->
    <div class="modal fade create-workform" id="create-event" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="popup text-left">
                        <h4 class="mb-3 text-center">Choisissez une date pour continuer</h4>
                        <div class="mb-3 d-flex justify-content-center align-items-center">
                            <input type="date" name="date" :min="currentDate" id="date" v-model="rdvDate">
                        </div>
                        <div class="mt-3">
                            <div class="d-flex flex-wrap align-items-ceter justify-content-center">
                                <div class="btn btn-primary mr-4" data-dismiss="modal">Annuler</div>
                                <div class="btn btn-primary mr-4" :class="{ disabled: rdvDateNull }"
                                    data-dismiss="modal" v-on:click="prepareToOperarationview()">
                                    Continuer
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--Modal Create Evevent-->

    <div class="vld-parent">
        <loading v-model:active="isLoading" can-cancel="false" is-full-page="true" />
    </div>
</template>

<script>

import axios from 'axios'
// import Swal from 'sweetalert2'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Swal from 'sweetalert2'

export default {

    name: 'EntrepriseViewVue',

    components: {
        // eslint-disable-next-line vue/no-unused-components
        Loading,
    },

    props: {
        secteurId: {
            required: true,
        },

        secteurLibelle: {
            required: true,
        }

    },

    data() {
        return {
            load: false,
            isLoading: false,
            dataEntreprise: null,
            listEntreprise: [],
            dataError: null,
            rdvDate: 'null',
            entrepriseTarget: {
                id: 0,
                nom: 'null',
            },
            noEntreprise: true,
            dataRdv: null,
            operation_found: null,
            entreprise_found: null,
        }
    },

    computed: {
        currentDate() {
            let today = new Date()
            const current_date = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0')
            return current_date
        },

        rdvDateNull() {
            return (this.rdvDate == null || this.rdvDate == 'null')
        },

        isCorrectCode() {
                    let answer = false

                    if (this.dataRdv != null) {
                        if (this.dataRdv.length != 0) {
                            answer = true
                        }
                    }
                    return answer
        },
        
    },

    methods: {

        

        getEntreprise() {
            this.load = true
            axios.get(this.$store.state.serveur + 'api/getentsect/' + this.secteurId)
                .then((response) => {
                    this.dataEntreprise = response.data.data
                    if (this.dataEntreprise.length != 0) {
                        for (let i = 0; i < this.dataEntreprise.length; i++) {
                            let entreprise = this.dataEntreprise[i]
                            if (entreprise.status == 1) {
                                this.listEntreprise.push(entreprise)
                            }
                        }

                        if (this.listEntreprise.length != 0) {
                            this.noEntreprise = false
                        }
                        this.load = false
                    }
                })
                .catch(e => (this.dataError = e))
        },

        updateTarget(id, nom) {
            this.entrepriseTarget.id = id
            this.entrepriseTarget.nom = nom
            this.prepareToOperarationview()
        },

        dateRefactor(date) {

            let indexTiret1 = ''
            let indexTiret1F = false
            let indexTiret2
            let annee = ''
            let mois = ''
            let jour = ''

            for (let i = 0; i < date.length; i++) {
                if (date[i] == '-' && !indexTiret1F) {
                    indexTiret1 = i
                    indexTiret1F = true
                }

                if (date[i] == '-') {
                    indexTiret2 = i
                }
            }

            for (let i = 0; i < date.length; i++) {

                if (i >= 0 && i < indexTiret1) {
                    annee += date[i]
                }
                else if (i > indexTiret1 && i < indexTiret2) {
                    mois += date[i]
                } else if (i > indexTiret2) {
                    jour += date[i]
                }
            }

            return { annee: parseInt(annee), mois: parseInt(mois), jour: parseInt(jour) }
        },

        prepareToOperarationview() {
            this.isLoading = true
            axios.get(this.$store.state.serveur + 'api/getjour/' + this.entrepriseTarget.id)
                .then((response) => {
                    const daysNumber = []
                    response.data.data[0].jours.forEach((day) => {
                        daysNumber.push(day.day_number)
                    })
                    // const dt = new Date(this.rdvDate).getDay()
                    // this.isLoading = false
                    // if (daysNumber.includes(dt)) {
                        this.$router.push({ name: 'calendar', params: { secId: this.secteurId, entId: this.entrepriseTarget.id, entNom: this.entrepriseTarget.nom, secLib: this.secteurLibelle } })
                    // } else {
                    //     Swal.fire({
                    //         icon: 'warning',
                    //         title: 'Désolé !',
                    //         text: 'Le jour choisie n\'entre pas dans l\'emploie du temps de cette entreprise. Veillez choisir un autre jour.'
                    //     })
                    // }
                }).catch((error) => {
                    this.isLoading = false
                    console.error(error)
                })
        },
        checkCode() {
                    this.load = true
                    this.codeAttempt = this.codeRdvInput
                    axios.get(this.$store.state.serveur + 'api/getcode/' + this.codeAttempt)
                        .then((response) => {
                            console.log(response.data)
                            this.dataRdv = response.data.data
                            if (this.dataRdv != null) {
                                if (this.dataRdv.length != 0) {
                                    axios.get(this.$store.state.serveur + 'api/operation/' + this.dataRdv[0].operation_id)
                                        .then((res) => {
                                            this.operation_found = res.data.data
                                            axios.get(this.$store.state.serveur + 'api/entreprise/' + this.operation_found.entreprise_id.toString())
                                                .then((r) => {
                                                    this.entreprise_found = r.data.data
                                                })
                                        })
                                }
                            }
                            this.load = false
                        })
                        .catch((error) => {
                            this.load = false
                            console.error(error)
                        })
        },
        cancelRdv(rdvId) {
                    this.load = true
                    this.codeAttempt = this.codeRdvInput
                    axios.post(this.$store.state.serveur + 'api/update1/' + rdvId, {
                                status: 2
                            })
                        .then((response) => {
                            console.log(response)
                            this.load = false
                            if (response.data.status == true) {
                                Swal.fire({
                                    title: 'Action effectué avec succès',
                                    icon: 'success',
                                    timer: 1500
                                })
                                this.dataRdv = response.data.data
                            }
                        })
                        .catch((error) => {
                            this.load = false
                            console.error(error)
                        })
        },

        rdvExpired(dt){
            const now = new Date()
            const date = new Date(dt)

            const toReturn = now > date ? true : false
            return toReturn
        }
    },

    created() {
       
        
        this.getEntreprise()
    }

}

</script>
