<template>


    <HeadVue dashActive="true" />
    <div class="content-page">
        <div>
            <div class="col-lg-12 mb-3">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="navbar-breadcrumb">
                        <h2 class="mb-1">---- -- ------- ----------</h2>
                    </div>
                </div>
            </div>
            <div class="container" v-show="this.$store.state.role == 'admin' || this.$store.state.role == 'admin_user'">
                <div class="d-flex justify-content-between">
                    <div class="navbar-breadcrumb d-flex align-items-center">
                        <h1 class="mb-1 "><span class="badge badge-primary">Timing de l'entreprise</span></h1>
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-5">
                    <div class="navbar-breadcrumb align-items-center">
                        <h5 class="mb-1">Heure de début </h5>
                        <div class="row align-items-center">
                            <h3>
                                <span class="badge badge-primary">
                                    {{ entrepriseTiming.heure_debut }}
                                </span>
                            </h3>
                            <i @click="setHeureModif(entrepriseTiming.heure_debut, 1)" class="item ri-pencil-line ml-2"
                                data-toggle="modal" data-target="#create-event-hours" title=" Modifier"></i>
                        </div>
                    </div>
                    <div class="navbar-breadcrumb">
                        <h5 class="mb-1">Heure de pause</h5>
                        <div class="row align-items-center">
                            <h3>
                                <span class="badge badge-info">
                                    {{ entrepriseTiming.heure_pose }}
                                </span>
                            </h3>
                            <i @click="setHeureModif(entrepriseTiming.heure_pose, 2)" class="item ri-pencil-line ml-2"
                                data-toggle="modal" data-target="#create-event-hours" title=" Modifier"></i>
                        </div>
                    </div>
                    <div class="navbar-breadcrumb">
                        <h5 class="mb-1">Heure de fin de pause</h5>
                        <div class="row align-items-center">
                            <h3>
                                <span class="badge badge-info">
                                    {{ entrepriseTiming.heure_fpose }}
                                </span>
                            </h3>
                            <i @click="setHeureModif(entrepriseTiming.heure_fpose, 3)" class="item ri-pencil-line ml-2"
                                data-toggle="modal" data-target="#create-event-hours" title=" Modifier"></i>
                        </div>
                    </div>
                    <div class="navbar-breadcrumb">
                        <h5 class="mb-1">Heure de fin</h5>
                        <div class="row align-items-center">
                            <h3>
                                <span class="badge badge-primary">
                                    {{ entrepriseTiming.heure_fin }}
                                </span>
                            </h3>
                            <i @click="setHeureModif(entrepriseTiming.heure_fin, 4)" class="item ri-pencil-line ml-2"
                                data-toggle="modal" data-target="#create-event-hours" title=" Modifier"></i>
                        </div>
                    </div>
                </div>
                <div class="navbar-breadcrumb mt-5">
                    <h5 class="mb-1">Jours de travail</h5>
                    <div class="row align-items-center">
                        <h3 v-for="jour in listJourEntreprise.all" :key="jour.id" class="mr-2">
                            <span class="badge badge-warning">
                                {{ jour.label }}
                            </span>
                        </h3>
                        <i class="item ri-pencil-line ml-2" data-toggle="modal" data-target="#create-event-days"
                            title="Modifier"></i>
                    </div>
                </div>
                <hr style="width:100%">
                <div class="col-lg-12 mt-5">
                    <div class="card card-block card-stretch card-height">
                        <div class="card-header d-flex justify-content-between">
                            <div class="iq-header-title">
                                <h2 class="mb-1">Specialité la plus sollicitée </h2>
                            </div>
                            <div class="float-sm-right ml-5">
                                <div class="mr-1 btn btn-primary pr-5 position-relative" style="height: 40px;"
                                    data-toggle="modal" data-target="#create-event">
                                    Définir une période<span class="event-add-btn"><i class="ri-bill-fill"></i></span>
                                </div>
                                <div @click="getAllSolOperation" class="ml-1 btn btn-primary pr-5 position-relative"
                                    style="height: 40px;">
                                    Tous<span class="event-add-btn"><i class="ri-bill-fill"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive data-table">
                                <table class="table" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>Specialité</th>
                                            <th>Nombre de solicitation</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(solOp,index) in solOperation" :key="solOp">
                                                <td >{{ solOp }} </td>
                                                <td> {{ solOperationValues[index] }} </td>
                                            </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <OperateurComponent v-show="this.$store.state.role == 'user'" />
    </div>

    <div class="modal fade create-workform" id="create-event-hours" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="popup text-left">
                        <h4 class="mb-3 text-center">Modification du timing: jours de travail</h4>
                        <div class="mt-3">
                            <form class="d-flex justify-center align-items-center mb-3">
                                <input class="ml-5 mr-5" v-model="heureModif.heure" id="heure" type="time" required />
                                <input @click="updateTiming()" type="submit" class="btn btn-primary ml-5 mr-2"
                                    data-dismiss="modal" value="Confirmer">
                                <input type="button" class="btn btn-primary mr-2" data-dismiss="modal" value="Annuler">
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade create-workform" id="create-event-days" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="popup text-left">
                        <h4 class="mb-3 text-center">Modification du timing: jours de travail</h4>
                        <div class="mt-3">
                            <form class="d-flex mb-3">
                                <div v-for="jour in listJour.all" :key="jour.id"
                                    class="d-sm-flex align-items-center justify-content-center flex-wrap">
                                    <input type="checkbox" checked :id="'day' + jour.id" :value="jour.id"
                                        v-if="listJourEntreprise.ids.includes(jour.id)"
                                        v-on:click="toogleDays(jour.id)">
                                    <input type="checkbox" :id="'day' + jour.id" :value="jour.id"
                                        v-on:click="toogleDays(jour.id)" v-else>
                                    <label :for="'day' + jour">{{ jour.label }}</label>
                                </div>
                            </form>
                            <button @click="updateDays()" data-dismiss="modal" class="btn btn-primary">
                                Confirmer
                            </button>
                            <div class="btn btn-primary ml-1" data-dismiss="modal">
                                Annuler
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade create-workform" id="create-event" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="popup text-left">
                        <h4 class="mb-3 text-center">Définissez une période</h4>
                        <div class="mt-3">
                            <div class="d-flex flex-wrap align-items-ceter justify-content-center mb-2">
                                <!--<div class="btn btn-primary mr-4" data-dismiss="modal">Anuller</div>-->
                                <div class="col-lg-6">
                                    <label class="form-label" for="debut">Début </label>
                                    <input v-model="periode.debut" class="form-control" type="date" name="debut"
                                        id="debut" required />
                                </div>
                                <div class="col-lg-6">
                                    <label class="form-label" for="fin">Fin </label>
                                    <input v-model="periode.fin" :min="periode.debut" class="form-control" type="date"
                                        name="fin" id="fin" required />
                                </div>
                            </div>
                            <div @click="getAllSolOperationPeriode" :class="{ disabled: periodeNotchosen }"
                                class="btn btn-primary" data-dismiss="modal">
                                Chercher
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="vld-parent">
        <loading v-model:active="isLoading" can-cancel="false" is-full-page="true" />
    </div>

    <FootVue />

</template>



<script>

import FootVue from '@/components/admin/fixed/FootVue.vue'
import HeadVue from '@/components/admin/fixed/HeadVue.vue'
import OperateurComponent from '@/components/admin/fixed/OperateurComponent.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import axios from 'axios'
import Swal from 'sweetalert2'

export default {

    name: 'DashboardView',
    components: {
        HeadVue,
        FootVue,
        OperateurComponent,
        Loading,
    },

    data() {
        return {
            isLoading: false,
            entrepriseTiming: null,
            solOperation: [],
            solOperationValues:[],
            periode: {
                debut: '',
                fin: '',
            },
            search: false,
            listRdvOperateur: [],
            listRdvOperateurTraite: [],
            listJour: { all: [], ids: [] },
            listChangeJour: [],
            listJourEntreprise: { all: [], ids: [] },
            heureModif: { heure: "", ref: 0 }
        }
    },

    computed: {
        periodeNotchosen() {
            return (this.periode.debut == '' || this.periode.debut == null || this.periode.fin == '' || this.periode.fin == null)
        }
    },

    methods: {
        getAllSolOperation() {
            this.search = false
            axios.get(this.$store.state.serveur + 'api/dossiers/' + this.$store.state.user.entreprise_id)
                .then((response) => {
                    this.solOperation = Object.keys(response.data.data)
                    this.solOperationValues = Object.values(response.data.data)
                }).catch((error) => {
                    console.error(error)
                })
        },

        getAllSolOperationPeriode() {
            this.search = true
            axios.post(this.$store.state.serveur + 'api/dossierp/' + this.$store.state.user.entreprise_id, {
                date_debut: this.periode.debut,
                date_fin: this.periode.fin
            }).then((response) => {
                console.log(response)
                this.solOperation = Object.keys(response.data.data)
               this.solOperationValues = Object.values(response.data.data)
            }).catch((error) => {
                console.error(error)
            })
        },

        getEntJours() {

            this.listJourEntreprise = { all: [], ids: [] }
            this.listChangeJour = []
            axios.get(this.$store.state.serveur + 'api/getjour/' + this.$store.state.user.entreprise_id)
                .then((response) => {
                    this.listJourEntreprise.all = response.data.data[0].jours
                    console.log("JOURS", response.data.data[0].jours)
                    this.listJourEntreprise.all.forEach((day) => {
                        this.listJourEntreprise.ids.push(day.id)
                        this.listChangeJour.push(day.id)
                    })

                }).catch((error) => {
                    console.error(error)
                })
        },

        getEntHeure() {

            axios.get(this.$store.state.serveur + "api/gettemps/" + this.$store.state.user.entreprise_id)
                .then((response) => {
                    this.load = false
                    this.entrepriseTiming = response.data.data[0]
                }).catch((error) => {
                    this.load = false
                    console.error(error)
                })
        },

        getJours() {

            axios.get(this.$store.state.serveur + 'api/jours')
                .then((response) => {
                    this.listJour.all = response.data.data
                    this.listJour.all.forEach((day) => {
                        this.listJour.ids.push(day.day_number)
                    })
                }).catch((error) => {
                    console.error(error)
                })
        },

        toogleDays(day) {
            if (this.listChangeJour.includes(day)) {
                let days = []
                this.listChangeJour.forEach((d) => {
                    if (d != day)
                        days.push(d)
                })
                this.listChangeJour = days
            } else {
                this.listChangeJour.push(day)
            }
        },

        updateDays() {
            this.isLoading = true
            axios
                .put(this.$store.state.serveur + 'api/entjour/' + this.$store.state.user.entreprise_id,
                    {
                        jours: this.listChangeJour
                    }
                )
                .then((response) => {
                    this.isLoading = false
                    if (response.data.status == "true") {
                        this.getEntJours()
                        Swal.fire({
                            title: 'Modification effectuée avec succès',
                            icon: 'success',
                            timer: 1500
                        })
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    console.error(error)
                })
        },

        setHeureModif(heure, ref) {
            this.heureModif = { heure: heure, ref: ref }
            console.log("HEURE MODIF", this.heureModif.heure, this.heureModif.ref)
        },

        updateTiming() {
            console.log("UPDATING TIMING")
            this.isLoading = true
            if (this.heureModif.heure != null && this.heureModif.heure != 'null') {
                switch (this.heureModif.ref) {
                    case 1:
                        axios.put(this.$store.state.serveur + "api/temps/" + this.$store.state.user.entreprise_id, {
                            heure_debut: this.heureModif.heure.toString(),
                            heure_pose: this.entrepriseTiming.heure_pose,
                            heure_fpose: this.entrepriseTiming.heure_fpose,
                            heure_fin: this.entrepriseTiming.heure_fin
                        }).then((res) => {
                            this.getEntHeure()
                            console.log(res)
                            this.isLoading = false
                            Swal.fire({
                                title: 'Modification éffectuée',
                                icon: 'success',
                                timer: 1500
                            })
                        }).catch((err) => {
                            this.isLoading = false
                            console.error(err)
                        })
                        break
                    case 2:
                        axios.put(this.$store.state.serveur + "api/temps/" + this.$store.state.user.entreprise_id, {
                            heure_debut: this.entrepriseTiming.heure_debut,
                            heure_pose: this.heureModif.heure.toString(),
                            heure_fpose: this.entrepriseTiming.heure_fpose,
                            heure_fin: this.entrepriseTiming.heure_fin
                        }).then((res) => {
                            this.getEntHeure()
                            console.log(res)
                            this.isLoading = false
                            Swal.fire({
                                title: 'Modification éffectuée',
                                icon: 'success',
                                timer: 1500
                            })
                        }).catch((err) => {
                            this.isLoading = false
                            console.error(err)
                        })
                        break
                    case 3:
                        axios.put(this.$store.state.serveur + "api/temps/" + this.$store.state.user.entreprise_id, {
                            heure_debut: this.entrepriseTiming.heure_debut,
                            heure_pose: this.entrepriseTiming.heure_pose,
                            heure_fpose: this.heureModif.heure.toString(),
                            heure_fin: this.entrepriseTiming.heure_fin
                        }).then((res) => {
                            this.getEntHeure()
                            console.log(res)
                            this.isLoading = false
                            Swal.fire({
                                title: 'Modification éffectuée',
                                icon: 'success',
                                timer: 1500
                            })
                        }).catch((err) => {
                            this.isLoading = false
                            console.error(err)
                        })
                        break
                    case 4:
                        axios.put(this.$store.state.serveur + "api/temps/" + this.$store.state.user.entreprise_id, {
                            heure_debut: this.entrepriseTiming.heure_debut,
                            heure_pose: this.entrepriseTiming.heure_pose,
                            heure_fpose: this.entrepriseTiming.heure_fpose,
                            heure_fin: this.heureModif.heure.toString()
                        }).then((res) => {
                            this.getEntHeure()
                            console.log(res)
                            this.isLoading = false
                            Swal.fire({
                                title: 'Modification éffectuée',
                                icon: 'success',
                                timer: 1500
                            })
                        }).catch((err) => {
                            this.isLoading = false
                            console.error(err)
                        })
                        break
                    default:
                        Swal.fire({
                            title: 'Oops',
                            text: 'Erreur inconue',
                            icon: 'error'
                        })
                }
            } else {
                this.isLoading = false
            }
        }
    },

    created() {

        this.getAllSolOperation()
        this.getJours()
        this.getEntHeure()
        this.getEntJours()

    }
}

</script>

<style>
.dropdown-item:hover {
    cursor: pointer;
}
</style>