<template>

    <div class="content-page  text-center">
        <div class="mb-10 text-center">
            <h3>Vos notes</h3>
            <div class="d-flex flex-row justify-content-center">
                <div v-for="n in 5" :key="n" class="p-2">
                    <i class="ri-star-line" :class="n < star+1 ? 'checked':'' " aria-hidden="false" @click="rate(n)"></i>
                </div>
            </div>
        </div>
        <div class="">
            <form @submit.prevent="sendMessage">
                <div class="row">
                    <div class="col-12">
                        <label for="review_comment">Votre avis</label>
                        <textarea v-model="message" style="background-color:white;"  class="form-control" ></textarea>
                    </div> 
                        
                </div> <br>

                <button type="submit" class="border border-success">Envoyer</button>
            </form>   
        </div> 
    </div>
</template>
<script>

    import axios from 'axios'
    import Swal from 'sweetalert2'
    
        export default {

            name: 'app',            

            data() {
                return {
                    waitingline: [],
                    star:0,
                    message:'',
                }
            },
            computed: {
                
                
            },
            methods: {
                getSecteur() {
                    this.isLoading =true
                        axios.get(this.$store.state.serveur + 'api/satisfaction/'+this.$route.params.rdvid+'/'+this.$route.params.number)
                        .then(res => {
                            if (!res.data.status) {
                                this.$router.push('/')
                            }
                            this.isLoading =false
                        })
                        .catch(err =>{
                            this.isLoading =false
                            console.log(err)
                        })
                },
                rate(n){
                    this.star=n
                },
                sendMessage(){
                       this.isLoading =true
                        axios.post(this.$store.state.serveur + 'api/satisfaction',{
                            number:this.$route.params.number,
                            rate:this.star,
                            message:this.message,
                            rdvId:this.$route.params.rdvid
                        })
                        .then(res => {
                            console.log(res)
                            Swal.fire({
                                title: 'Merci',
                                text: 'Merci pour votre contribution',
                                icon: 'success',
                                timer: 1500
                            }).then(() => {
                                this.$router.go()
                            })
                        this.panier=  res.data
                        this.isLoading =false
                        })
                        .catch(err =>{
                            this.isLoading =false
                            console.log(err)
                        })
                    
                },
            },

            created() {
                this.getSecteur()
                
            }
            
        };

</script>

<style scoped>
.checked {
  color: orange;
}
</style>