<template>


    <HeadVue opeActive="true" />

    <div class="content-page">
        <div class="col-lg-12 mb-3">
            <div class="d-flex align-items-center justify-content-between">
                <div class="navbar-breadcrumb">
                    <h2 class="mb-1">---- -- ------- ----------</h2>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div>
                    <div class="col-lg-12 mb-3">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="navbar-breadcrumb">
                                <h2 class="mb-1">Créer une nouvelle specialité</h2>
                            </div>
                        </div>
                    </div>
                    <div class="tab-extra active" id="search-with-button">
                        <div class="d-flex flex-wrap mb-4">
                            <div>
                                <div class="iq-search-bar search-device mb-0 pr-3">
                                    <div class="searchbox">
                                        <input v-model="newOperation.libelle" type="text" class="text search-input"
                                            placeholder="Libellé de l'opération...">
                                    </div>
                                </div>
                                <div class="iq-search-bar search-device mb-0 pr-3">
                                    <div class="searchbox">
                                        <input v-model="newOperation.temps_passage" type="text"
                                            class="text search-input" placeholder="Temps de passage...">
                                    </div>
                                </div>
                                <div class="iq-search-bar search-device mb-0 pr-3">
                                    <div class="searchbox">
                                        <input v-model="newOperation.start" type="time"
                                            class="text search-input" placeholder="Heure d'ouverture...">
                                    </div>
                                </div>
                                <div class="iq-search-bar search-device mb-0 pr-3">
                                    <div class="searchbox">
                                        <input v-model="newOperation.end" type="time"
                                            class="text search-input" placeholder="Heure de fermerture...">
                                    </div>
                                </div>
                                <div class="d-flex flex-row">
                                        <div v-for="jour in listJour.all" :key="jour.id"
                                            class="p-2">
                                            <input type="checkbox" :id="'day' + jour.id" v-model="jour.check" :value="jour.id">
                                            <label :for="'day' + jour">{{ jour.label }}</label>
                                        </div>
                                </div>
                            </div>
                            
                            <div class="float-sm-right">
                                <div class="btn btn-primary pr-5 position-relative" @click="addNewOperation"
                                    :class="{ disabled: !newOpFieldsFull }" style="height: 40px;">Ajouter<span
                                        class="event-add-btn"><i class="ri-add-line"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="width:100%">
                <div class="col-lg-12">
                    <div class="card card-block card-stretch card-height">
                        <div class="card-header d-flex justify-content-between">
                            <div class="iq-header-title">
                                <h2 class="mb-1">Liste des specialités</h2>
                            </div>
                            <LoaderVue v-if="load" />
                            <div class="float-sm-right ml-5">
                                <div class="btn btn-primary pr-5 position-relative" @click="getAllOperation(true)"
                                    style="height: 40px;">
                                    Actualiser<span class="event-add-btn"><i class="ri-bill-fill"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive data-table">
                                <div>
                                    <h5>Nombre total: {{ listOperation.length }} </h5>
                                </div>
                                <table class="table" id="table_operations" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>Libellé</th>
                                            <th>Heure d'ouverture</th>
                                            <th>Heure de fermerture</th>
                                            <th>Temps de passage</th>
                                            <th>Options</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="operation in listOperation" :key="operation.id">
                                            <td> {{ operation.libelle }} </td>
                                            <td> {{ operation.start }} </td>
                                            <td> {{ operation.end }} </td>
                                            <td> {{ operation.temps_passage }} </td>
                                            <td>
                                                <div class="d-flex align-items-center list-action">
                                                    <a class="dropdown-item" data-toggle="modal"
                                                        data-target="#create-event"
                                                        @click="setUpdateNewOperationId(operation, operation.id)"><i
                                                            class="ri-pencil-line mr-3"></i>Modifier</a>
                                                    <a class="dropdown-item" @click="deleteOperation(operation.id)"><i
                                                            class="ri-delete-bin-6-line mr-3"></i>Supprimer</a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="modal fade create-workform" id="create-event" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="popup text-left">
                        <h4 class="mb-3 text-center">Modification specialité</h4>
                        <div class="mt-3">
                            <div class="d-flex flex-wrap align-items-ceter justify-content-center">
                                <!--<div class="btn btn-primary mr-4" data-dismiss="modal">Anuller</div>-->
                                <div class="col-lg-6">
                                    <div class="floating-input form-group">
                                        <input v-model="upNewOperation.libelle" class="form-control" type="text"
                                            name="libelle" id="libelle" required />
                                        <label class="form-label" for="name">Libelle </label>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="floating-input form-group">
                                        <input v-model="upNewOperation.temps_passage" class="form-control" type="text"
                                            name="temps_passage" id="temps_passage" required />
                                        <label class="form-label" for="email">Temps de passage </label>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="floating-input form-group">
                                        <input v-model="upNewOperation.start" class="form-control" type="time"
                                            name="temps_passage" id="temps_passage" required />
                                        <label class="form-label" for="email">Heure d'ouverture</label>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="floating-input form-group">
                                        <input v-model="upNewOperation.end" class="form-control" type="time"
                                            name="temps_passage" id="temps_passage" required />
                                        <label class="form-label" for="email"> Heure de fermeture</label>
                                    </div>
                                </div>
                                <div class="d-flex flex-row">
                                        <div v-for="jour in upNewOperation.jour_all" :key="jour.id"
                                            class="p-2">
                                            <input type="checkbox" checked :id="'day' + jour.id" v-model="jour.check" 
                                                v-if="upNewOperation.jours.includes(jour.day_number)"
                                                >
                                            <input type="checkbox" :id="'day' + jour.id"  v-model="jour.check"
                                                 v-else>
                                            <label :for="'day' + jour.id">{{ jour.label }}</label>
                                        </div>
                                </div>
                            </div>
                            <div :class="{ disabled: !upNewOpFieldsFull }" class="btn btn-primary"
                                @click="updateOperation()" data-dismiss="modal">
                                Confirmer
                            </div>
                            <div class="btn btn-primary ml-1" data-dismiss="modal">
                                Annuler
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="vld-parent">
        <loading v-model:active="isLoading" can-cancel="false" is-full-page="true" />
    </div>

    <FootVue />

</template>



<script>

import FootVue from '@/components/admin/fixed/FootVue.vue'
import HeadVue from '@/components/admin/fixed/HeadVue.vue'
import LoaderVue from '@/components/admin/fixed/Loader.vue'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import axios from 'axios'
import Swal from 'sweetalert2'

import $ from "jquery"

export default {

    name: 'OperationView',
    components: {
        HeadVue,
        FootVue,
        LoaderVue,
        // eslint-disable-next-line vue/no-unused-components
        Loading,
    },

    data() {
        return {
            load: false,
            isLoading: false,
            listOperation: [],
            newOperation: {
                libelle: '',
                temps_passage: ''
            },
            upNewOperation: {
                libelle: '',
                temps_passage: '',
                id: 0
            },
            listJour: { all: [], ids: [] },
        }
    },

    computed: {
        newOpFieldsFull() {
            return (this.newOperation.libelle.length != 0 && parseInt(this.newOperation.temps_passage) > 0)
        },

        upNewOpFieldsFull() {
            return (this.upNewOperation.libelle.length != 0 && parseInt(this.upNewOperation.temps_passage) > 0)
        }
        
    },

    methods: {
        getJours() {

        axios.get(this.$store.state.serveur + 'api/jours')
            .then((response) => {
                this.listJour.all = response.data.data
                this.listJour.all.forEach((day) => {
                    this.listJour.ids.push(day.day_number)
                })
            }).catch((error) => {
                console.error(error)
            })
        },
        applyDataTable(id) {
            $(document).ready(function () {
                $(id).DataTable(
                    {
                        "lengthMenu": [ 5,10, 25, 50, 75, 100 ],
                        "pageLength": 10,
                        order: [[3, 'desc']],
                        stateSave: true,
                        "bDestroy": true
                    }
                );
            })
        },

        getAllOperation(withLoad = false) {
            if (withLoad == true) {
                this.load = true
            }
            axios
                .get(this.$store.state.serveur + 'api/geto/' + this.$store.state.user.entreprise_id)
                .then((response) => {
                    this.load = false
                    this.applyDataTable('#table_operations')
                    this.listOperation = response.data.data
                })
                .catch((error) => {
                    this.load = false
                    console.error(error)
                })
        },

        addNewOperation() {
            this.isLoading = true
            axios
                .post(this.$store.state.serveur + 'api/operation',
                    {
                        start: this.newOperation.start,
                        end: this.newOperation.end,
                        libelle: this.newOperation.libelle,
                        temps_passage: parseInt(this.newOperation.temps_passage),
                        entreprise_id: parseInt(this.$store.state.user.entreprise_id),
                        jour: this.listJour.all
                    }
                )
                .then((response) => {
                    this.isLoading = false
                    this.getAllOperation()
                    console.log(response)
                    Swal.fire({
                        title: 'Opération ajoutée',
                        icon: 'success'
                    })
                    this.newOperation = {
                        libelle: '',
                        temps_passage: ''
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    console.log(error)
                    Swal.fire({
                        title: 'Oops',
                        text: "Cette opération existe déja",
                        icon: 'error',
                    })
                })
        },

        setUpdateNewOperationId(operation, operation_id) {
            this.upNewOperation.id = operation_id
            this.upNewOperation.libelle = operation.libelle
            this.upNewOperation.start = operation.start
            this.upNewOperation.end = operation.end
            this.upNewOperation.temps_passage = operation.temps_passage
            this.upNewOperation.jour_all = this.listJour.all
            let array =  operation.days.split(",")
            array.pop()
            this.upNewOperation.jours = []
            array.forEach(element => {
                this.upNewOperation.jours.push(Number(element))
            });
            this.upNewOperation.jour_all.forEach(element => {
                if (this.upNewOperation.jours.includes(element.day_number)) {
                    element.check = true
                }else{
                    element.check = false
                }
            });
            // this.upNewOperation.jours =array;

            console.log(this.upNewOperation)
        },

        updateOperation() {
            this.isLoading = true
            axios
                .put(this.$store.state.serveur + 'api/operation/' + this.upNewOperation.id,
                    {
                        libelle: this.upNewOperation.libelle,
                        temps_passage: parseInt(this.upNewOperation.temps_passage),
                        jour: this.upNewOperation.jour_all,
                        start: this.upNewOperation.start,
                        end: this.upNewOperation.end,
                    })
                .then((response) => {
                    this.isLoading = false
                    console.log(response)
                    if (response.data.status == 'true') {
                        this.getAllOperation()
                        Swal.fire({
                            title: 'Modification effectuée',
                            icon: 'success',
                            timer: 1500
                        })
                    } else {
                        this.isLoading = false
                        Swal.fire({
                            title: 'Oops',
                            text: response.data.data,
                            icon: 'error',
                        })
                    }
                })
                .catch((error) => {
                    console.error(error)
                    Swal.fire({
                        title: 'Oops',
                        text: "Cette opération existe déja",
                        icon: 'error',
                    })
                })
        },

        deleteOperation(operation_id) {
            Swal.fire({
                title: 'Voulez-vous vraiment supprimer cette opération ?',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Non',
                confirmButtonText: 'Oui'
            }).then((value) => {
                if (value.isConfirmed) {
                    this.isLoading = true
                    axios.delete(this.$store.state.serveur + 'api/operation/' + operation_id)
                        .then((response) => {
                            console.log(response)
                            this.getAllOperation()
                            this.isLoading = false
                            if (response.data.status == 'true') {
                                Swal.fire({
                                    title: 'Suppression terminée',
                                    icon: 'success'
                                })
                            } else {
                                Swal.fire({
                                    title: 'Oops',
                                    text: response.data.message,
                                    icon: 'error'
                                })
                            }
                        }).catch((error) => {
                            this.isLoading = false
                            console.error(error)
                            Swal.fire({
                                title: 'Oops',
                                text: error.response.data.message,
                                icon: 'error'
                            })
                        })
                }
            })

        },
    },

    created() {
        this.getAllOperation(true)
        this.getJours()
    }
}

</script>

<style>
.dropdown-item:hover {
    cursor: pointer;
}
</style>