<template>


    <HeadVue rdvActive="true" />

    <div class="content-page" style="margin-top: 60px;">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card card-block card-stretch card-height">
                        <div class="card-body">
                            <ul class="nav nav-tabs justify-content-center" id="myTab-2" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="home-tab-justify" data-toggle="tab"
                                        href="#home-justify" role="tab" aria-controls="home" aria-selected="true">Tous
                                        les Rendez-vous</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="profile-tab-justify" data-toggle="tab"
                                        href="#profile-justify" role="tab" aria-controls="profile"
                                        aria-selected="false">Rendez-vous traités</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="contact-tab-justify" data-toggle="tab"
                                        href="#contact-justify" role="tab" aria-controls="contact"
                                        aria-selected="false">Rendez-vous non traités</a>
                                </li>
                            </ul>

                            <div class="tab-content" id="myTabContent-3">
                                <!--Tous les RDVs-->
                                <div class="tab-pane fade show active" id="home-justify" role="tabpanel"
                                    aria-labelledby="home-tab-justify">
                                    <div class="card-header d-flex justify-content-between">
                                        <div class="iq-header-title">

                                        </div>
                                        <LoaderVue v-if="load" />
                                        <div class="float-sm-right ml-5">
                                            <div v-if="periodeNotchosen"
                                                class="mr-1 btn btn-primary pr-5 position-relative"
                                                style="height: 40px;" data-toggle="modal" data-target="#create-event">
                                                Définir une période<span class="event-add-btn"><i
                                                        class="ri-bill-fill"></i></span>
                                            </div>
                                            <div v-else class="mr-1 btn btn-primary pr-5 position-relative"
                                                style="height: 40px;" data-toggle="modal" data-target="#create-event">
                                                {{ periode.debut }} - {{ periode.fin }}<span class="event-add-btn"><i
                                                        class="ri-bill-fill"></i></span>
                                            </div>
                                            <div @click="getAllRdv" class="ml-1 btn btn-primary pr-5 position-relative"
                                                style="height: 40px;">
                                                Tous<span class="event-add-btn"><i class="ri-bill-fill"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <div>
                                                <h5>Nombre total: {{ listRdv.length }} </h5>
                                            </div>

                                            <table class="table" id="table" style="width:100%">
                                                <thead>
                                                    <tr>
                                                        <th>Specialité</th>
                                                        <th>Nom&Prénoms</th>
                                                        <th>Email&Contact</th>
                                                        <th>Date&Heure</th>
                                                        <th>Code RDV</th>
                                                        <th>Status</th>
                                                        <th>Rapport</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="rdv in listRdv" :key="rdv.id">
                                                        <td> {{rdv.operation.libelle}} </td>
                                                        <td> {{ rdv.nom }} <br> {{ rdv.prenom }} </td>
                                                        <td> {{ rdv.email }} <br> {{ rdv.contact }} </td>
                                                        <td> {{ rdv.date.slice(0, 10) }} <br> {{ rdv.heure }} </td>
                                                        <td> {{ rdv.code }} </td>
                                                        <td>
                                                            <span class="text-danger" v-if="rdvExpired(rdv.date.slice(0, 10)+' '+rdv.heure) && rdv.status == 0"> Expiré </span>
                                                            <span class="text-success" v-else-if="rdv.status == 1"> Terminé </span>
                                                            <span class="text-info" v-else-if="rdv.status == 0"> En attente</span>
                                                            <span class="text-danger" v-else-if="rdv.status == 2"> Annulé</span>
                                                        </td>
                                                        <td class="text-center"> <button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#exampleModalLongRdv" @click="report = rdv"><i class="ri-information-line"></i></button>  </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <!--RDVS traités-->
                                <div class="tab-pane fade" id="profile-justify" role="tabpanel"
                                    aria-labelledby="profile-tab-justify">
                                    <div class="card-header d-flex justify-content-between">
                                        <div class="iq-header-title">

                                        </div>
                                        <LoaderVue v-if="load" />
                                        <div class="float-sm-right ml-5">
                                            <div v-if="periodeNotchosen"
                                                class="mr-1 btn btn-primary pr-5 position-relative"
                                                style="height: 40px;" data-toggle="modal" data-target="#create-event">
                                                Définir une période<span class="event-add-btn"><i
                                                        class="ri-bill-fill"></i></span>
                                            </div>
                                            <div v-else class="mr-1 btn btn-primary pr-5 position-relative"
                                                style="height: 40px;" data-toggle="modal" data-target="#create-event">
                                                {{ periode.debut }} - {{ periode.fin }}<span class="event-add-btn"><i
                                                        class="ri-bill-fill"></i></span>
                                            </div>
                                            <div @click="getAllRdv" class="ml-1 btn btn-primary pr-5 position-relative"
                                                style="height: 40px;">
                                                Tous<span class="event-add-btn"><i class="ri-bill-fill"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-responsive data-table">
                                            <div>
                                                <h5>Nombre total: {{ listRdvTraite.length }} </h5>
                                            </div>
                                            <table class="table" id="table_traite" style="width:100%">
                                                <thead>
                                                    <tr>
                                                        <th>Specialité</th>
                                                        <th>Nom&Prénoms</th>
                                                        <th>Email&Contact</th>
                                                        <th>Date&Heure</th>
                                                        <th>Id opérateur</th>
                                                        <th>Code RDV</th>
                                                        <th>Rapport</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="rdv in listRdvTraite" :key="rdv.id">
                                                        <td> {{rdv.operation.libelle}} </td>
                                                        <td> {{ rdv.nom }} <br> {{ rdv.prenom }} </td>
                                                        <td> {{ rdv.email }} <br> {{ rdv.contact }} </td>
                                                        <td> {{ rdv.date.slice(0, 10) }} <br> {{ rdv.heure }} </td>
                                                        <td> {{rdv.users_id}} </td>
                                                        <td> {{ rdv.code }} </td>
                                                        <td class="text-center"> <button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#exampleModalLongRdv" @click="report = rdv"><i class="ri-information-line"></i></button>  </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <!--RDV non traités-->
                                <div class="tab-pane fade" id="contact-justify" role="tabpanel"
                                    aria-labelledby="contact-tab-justify">
                                    <div class="card-header d-flex justify-content-between">
                                        <div class="iq-header-title">

                                        </div>
                                        <LoaderVue v-if="load" />
                                        <div class="float-sm-right ml-5">
                                            <div v-if="periodeNotchosen"
                                                class="mr-1 btn btn-primary pr-5 position-relative"
                                                style="height: 40px;" data-toggle="modal" data-target="#create-event">
                                                Définir une période<span class="event-add-btn"><i
                                                        class="ri-bill-fill"></i></span>
                                            </div>
                                            <div v-else class="mr-1 btn btn-primary pr-5 position-relative"
                                                style="height: 40px;" data-toggle="modal" data-target="#create-event">
                                                {{ periode.debut }} - {{ periode.fin }}<span class="event-add-btn"><i
                                                        class="ri-bill-fill"></i></span>
                                            </div>
                                            <div @click="getAllRdv" class="ml-1 btn btn-primary pr-5 position-relative"
                                                style="height: 40px;">
                                                Tous<span class="event-add-btn"><i class="ri-bill-fill"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-responsive data-table">
                                            <div>
                                                <h5>Nombre total: {{ listRdvNonTraite.length }} </h5>
                                            </div>
                                            <table class="table" id="table_non_traite" style="width:100%">
                                                <thead>
                                                    <tr>
                                                        <th>Specialité</th>
                                                        <th>Nom&Prénoms</th>
                                                        <th>Email&Contact</th>
                                                        <th>Date&Heure</th>
                                                        <th>Code RDV</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="rdv in listRdvNonTraite" :key="rdv.id">
                                                        <td> {{rdv.operation.libelle}} </td>
                                                        <td> {{ rdv.nom }} <br> {{ rdv.prenom }} </td>
                                                        <td> {{ rdv.email }} <br> {{ rdv.contact }} </td>
                                                        <td> {{ rdv.date.slice(0, 10) }} <br> {{ rdv.heure }} </td>
                                                        <td> {{ rdv.code }} </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <OperateurComponent v-show="this.$store.state.role == 'admin_user'" />
    </div>

    <div class="modal fade create-workform" id="create-event" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="popup text-left">
                        <h4 class="mb-3 text-center">Définissez une période</h4>
                        <div class="mt-3">
                            <div class="d-flex flex-wrap align-items-ceter justify-content-center mb-2">
                                <!--<div class="btn btn-primary mr-4" data-dismiss="modal">Anuller</div>-->
                                <div class="col-lg-6">
                                    <label class="form-label" for="debut">Début </label>
                                    <input v-model="periode.debut" class="form-control" type="date" name="debut"
                                        id="debut" required />
                                </div>
                                <div class="col-lg-6">
                                    <label class="form-label" for="fin">Fin </label>
                                    <input v-model="periode.fin" :min="periode.debut" class="form-control" type="date"
                                        name="fin" id="fin" required />
                                </div>
                            </div>
                            <div @click="getAllRdvPeriode" :class="{ disabled: periodeNotchosen }"
                                class="btn btn-primary" data-dismiss="modal">
                                Chercher
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="exampleModalLongRdv" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongRdvTitle" aria-hidden="true">
        <div class="modal-dialog" role="document" v-if="report">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongRdvTitle">Rapport de traitement</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <h5>Traité par:</h5>
                <p v-if="report.user">{{report.user.prenom}} {{report.user.nom}}</p>
                <hr>
                <h5>Resumé</h5>
                <p>{{report.resume}}</p>
                <hr>
                <h5>Retour client</h5>
                <p>Note:{{report.rate}}/5</p>
                <p>{{report.retour_client}}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
            </div>
        </div>
    </div>
    <FootVue />

</template>


<script>

import FootVue from '@/components/admin/fixed/FootVue.vue'
import HeadVue from '@/components/admin/fixed/HeadVue.vue'
import OperateurComponent from '@/components/admin/fixed/OperateurComponent.vue'
import LoaderVue from '@/components/admin/fixed/Loader.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import axios from 'axios'
import $ from "jquery"

export default {

    name: 'RdvView',
    components: {
        HeadVue,
        FootVue,
        // eslint-disable-next-line vue/no-unused-components
        OperateurComponent,
        LoaderVue,
        // eslint-disable-next-line vue/no-unused-components
        Loading,
    },

    data() {
        return {
            load: false,
            listRdv: [],
            listRdvNonTraite: [],
            listRdvTraite: [],
            periode: {
                debut: '',
                fin: '',
            },
            report:null
        }
    },

    computed: {
        periodeNotchosen() {
            return (this.periode.debut == '' || this.periode.fin == '')
        }
    },

    methods: {

        applyDataTable(id) {
            $(document).ready(function () {
                $(id).DataTable(
                    
                    {
                        "lengthMenu": [ 5,10, 25, 50, 75, 100 ],
                        "pageLength": 10,
                        order: [[3, 'desc']],
                        stateSave: true,
                        "bDestroy": true
                    }
                )
            })
        },

        getAllRdv() {
            this.listRdv = []
            this.listRdvTraite = []
            this.listRdvNonTraite = []
            this.periode.debut = ''
            this.periode.fin = ''
            this.load = true
            axios.get(this.$store.state.serveur + 'api/getoprdv/' + this.$store.state.user.entreprise_id)
                .then((response) => {
                    this.load = false
                    this.listRdv = response.data.data
                    this.listRdv.forEach(rdv => {
                        if (rdv.status == 0) {
                            this.listRdvNonTraite.push(rdv)
                        } else {
                            this.listRdvTraite.push(rdv)
                        }
                    })
                    
                    this.applyDataTable('#table')
                    this.applyDataTable('#table_traite')
                    this.applyDataTable('#table_non_traite')
                }).catch((error) => {
                    console.log(error)
                    this.load = false
                })

        },

        getAllRdvPeriode() {
            this.listRdv = []
            this.listRdvTraite = []
            this.listRdvNonTraite = []
            this.load = true

            if (this.periode.debut != '' && this.periode.fin != '') {
                axios.post(this.$store.state.serveur + 'api/date/' + this.$store.state.user.entreprise_id, {
                    date_debut: this.periode.debut,
                    date_fin: this.periode.fin
                }).then((response) => {
                    this.listRdv = response.data.data
                    this.listRdv.forEach(rdv => {
                        if (rdv.status == 0) {
                            this.listRdvNonTraite.push(rdv)
                        } else {
                            this.listRdvTraite.push(rdv)
                        }
                    })
                    this.load = false
                    this.applyDataTable('#table')
                    this.applyDataTable('#table_traite')
                    this.applyDataTable('#table_non_traite')
                }).catch((error) => {
                    console.error(error)
                    this.load = false
                })
            }
        },

        rdvExpired(dt){
            const now = new Date()
            const date = new Date(dt)

            const toReturn = now > date ? true : false
            return toReturn
        }
    },

    created() {
        this.getAllRdv()
    }
}

</script>