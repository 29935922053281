import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ConInsView from '../views/ConInsView.vue'
import EntrepriseView from '../views/EntrepriseView.vue'
import CalendarView from '../views/CalendarView.vue'
import SatisfactionView from '../views/satisfaction.vue'
import StatistiqueView from '../views/statistique.vue'

import DashboardView from '@/views/admin/dashboard/DashboardView.vue'
import OperationView from '@/views/admin/operation/OperationView.vue'
import RdvView from '@/views/admin/rdv/RdvView.vue'
import UtilisateurView from '@/views/admin/utilisateur/UtilisateurView.vue'

import Line from '@/views/line.vue'

const routes = [{
        path: '/',
        name: 'home',
        // redirect: '/entreprises/Informatique/5',
        component: HomeView
    },
    {
        path: '/satisfaction/:number/:rdvid',
        name: 'SatisfactionView',
        component: SatisfactionView
    },
    {
        path: '/line',
        name: 'line',
        component: Line
    },

    {
        path: '/conins',
        name: 'conins',
        component: ConInsView
    },

    {
        path: '/entreprises/:secLib/:secId',
        name: 'entreprises',
        component: EntrepriseView
    },

    {
        path: '/entrepries/calendar/:secLib/:secId/:entNom/:entId',
        name: 'calendar',
        component: CalendarView
    },

    {
        path: '/about',
        name: 'about',
        component: AboutView
    },

    {
        path: '/admin/dashboard',
        name: 'adminDashboard',
        component: DashboardView,
    },

    {
        path: '/admin/operations',
        name: 'adminOperation',
        component: OperationView,
    },

    {
        path: '/admin/rdvs',
        name: 'adminRdv',
        component: RdvView,
    },

    {
        path: '/admin/utilisateurs',
        name: 'adminUtilisateur',
        component: UtilisateurView,
    },
    {
        path: '/admin/statistiques',
        name: 'adminStatistique',
        component: StatistiqueView,
    }
]


const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router