<template>
    <div class="content-page">
        <div class="content-top" style="background-color: black;">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 col-md-8">
                        <ul class="d-flex nav nav-pills mb-4 text-center event-tab" id="event-pills-tab" role="tablist">
                            <li class="nav-item">
                                <a id="view-btn" class="nav-link active show" data-toggle="pill" href="#event1"
                                    data-extra="#search-with-button" role="tab" aria-selected="true">Connexion</a>
                            </li>
                            <!-- <li class="nav-item">
                                <a id="view-schedule" class="nav-link" data-toggle="pill" href="#event2"
                                    data-extra="#view-event" role="tab" aria-selected="false"
                                    v-on:click="getSecteurs">Inscription</a>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="event-content">
                        <div id="event1" class="tab-pane fade active show">
                            <div class="row">
                                <div class="col-lg-4 col-md-6"></div>
                                <div class="col-md-5 col-sm-12 col-12">
                                    <div class="card">
                                        <div class="card-body text-center">
                                            <h2>Connexion</h2>
                                            <p>Accéder à votre Espace en tant <br> que Entreprise ou Employé</p>
                                            <form @submit.prevent="connect">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="floating-input form-group">
                                                            <input class="form-control" type="text" name="email"
                                                                id="email" v-model="email" required />
                                                            <label class="form-label" for="email">Email</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="floating-input form-group">
                                                            <input class="form-control" type="password" name="password"
                                                                id="password" v-model="password" required />
                                                            <label class="form-label" for="password">Mot de
                                                                passe</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" class="btn btn-primary">
                                                    Se connecter
                                                </button>
                                                <div class="d-flex justify-content-center mt-2" v-if="load">
                                                    <div class="spinner-border" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="event2" class="tab-pane fade active">
                            <div class="row">
                                <div class="col-lg-4 col-md-6"></div>
                                <div class="col-md-5 col-sm-12 col-12 align-self-center">
                                    <div class="card">
                                        <div class="card-body text-center">
                                            <h2>Inscription</h2>
                                            <p>
                                                Inscrivez votre Entreprise afin de disposer d'un Espace
                                                Entreprise
                                            </p>
                                            <br />
                                            <form>
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="floating-input form-group">
                                                            <input class="form-control" type="text"
                                                                v-model="entrepriseInfo.nom" name="fullname"
                                                                id="fullname" required />
                                                            <label class="form-label" for="fullname">Nom de l'entreprise
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="floating-input form-group">
                                                            <select name="secteur" id="secteur"
                                                                v-model="entrepriseInfo.secteurId">
                                                                <option value="0">Secteur d'activité</option>
                                                                <option v-for="secteur in secteurList"
                                                                    :value="secteur.id" :key="secteur.id">
                                                                    {{ secteur.libelle }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="floating-input form-group">
                                                            <input class="form-control" type="text" name="adresse"
                                                                v-model="entrepriseInfo.adresse" id="fullname"
                                                                required />
                                                            <label class="form-label" for="adresse">Addresse
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="floating-input form-group">
                                                            <input class="form-control" type="text" name="location"
                                                                v-model="entrepriseInfo.lieu" id="fullname" required />
                                                            <label class="form-label" for="location">Localisation
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="floating-input form-group">
                                                            <input class="form-control" type="text" name="email"
                                                                v-model="entrepriseInfo.email" id="email" required />
                                                            <label class="form-label" for="email">Email</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="floating-input form-group">
                                                            <input class="form-control" type="text" name="contact"
                                                                v-model="entrepriseInfo.phone" id="contact" required />
                                                            <label class="form-label" for="contact">Contact</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="floating-input form-group">
                                                            <input class="form-control" type="password" name="password"
                                                                v-model="entrepriseInfo.password" id="password"
                                                                required />
                                                            <label class="form-label" for="password">Mot de
                                                                passe</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="floating-input form-group">
                                                            <input class="form-control" type="password"
                                                                name="c_password"
                                                                v-model="entrepriseInfo.confirmPssword" id="password1"
                                                                required />
                                                            <label class="form-label" for="c_password1">Confirmation mot
                                                                de passe
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="custom-control custom-checkbox mb-3 text-left">
                                                            <input type="checkbox" class="custom-control-input"
                                                                id="termes" v-model="entrepriseInfo.termes" />
                                                            <label class="custom-control-label" for="termes">J'accepte
                                                                les termes
                                                                d'utilisation</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="btn btn-primary" data-toggle="modal"
                                                    data-target="#create-event" :class="{ disabled: fields1NotFull }">
                                                    S'inscrire
                                                </div>
                                                <div class="d-flex justify-content-center mt-2" v-if="load">
                                                    <div class="spinner-border" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade create-workform" id="create-event" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="popup text-left">
                        <h4 class="mb-3 text-center">Enrégistrer un premier administrateur pour votre entreprise</h4>
                        <div class="mt-3">
                            <div class="d-flex flex-wrap align-items-ceter justify-content-center">
                                <div class="col-lg-6">
                                    <div class="floating-input form-group">
                                        <input class="form-control" v-model="adminInfo.nom" type="text" name="name"
                                            id="name" required />
                                        <label class="form-label" for="name">Nom </label>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="floating-input form-group">
                                        <input class="form-control" v-model="adminInfo.prenoms" type="text"
                                            name="surname" id="surname" required />
                                        <label class="form-label" for="surname">Prénoms </label>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="floating-input form-group">
                                        <input class="form-control" v-model="adminInfo.phone" type="text" name="contact"
                                            id="contact" required />
                                        <label class="form-label" for="contact">Contact </label>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" data-toggle="modal" data-target="#create-congrat"
                                class="btn btn-primary" :class="{ disabled: fields2NotFull }" @click="addEntreprise"
                                data-dismiss="modal">
                                Confirmer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import axios from 'axios'
import Swal from 'sweetalert2'

export default {
    data() {
        return {
            load: false,
            secteurList: null,
            entrepriseInfo: {
                nom: "",
                secteurId: 0,
                phone: "",
                email: "",
                password: "",
                confirmPssword: "",
                lieu: "",
                adresse: "",
                termes: false,
                status: 0,
            },

            adminInfo: {
                nom: "",
                prenoms: "",
                phone: "",
                entrepriseId: null,
                rolesId: 3,
            },
            email: '',
            password: ''
        }
    },

    computed: {
        fields1NotFull() {
            if (this.entrepriseInfo.nom == "" || this.entrepriseInfo.phone == "" || this.entrepriseInfo.email == "" || this.entrepriseInfo.password == "" || this.entrepriseInfo.confirmPssword == "" || this.entrepriseInfo.lieu == "" || this.entrepriseInfo.adresse == "" || this.entrepriseInfo.secteurId == "0" || this.entrepriseInfo.termes == false) {
                return true
            } else {
                if (this.entrepriseInfo.password != this.entrepriseInfo.confirmPssword) {
                    return true
                } else {
                    return false
                }
            }
        },

        fields2NotFull() {
            if (this.adminInfo.nom == "" || this.adminInfo.prenoms == "" || this.adminInfo.phone == "") {
                return true
            } else {
                return false
            }
        }
    },

    methods: {
        getSecteurs() {

            axios.get(this.$store.state.serveur + 'api/secteur')
                .then((response) => {
                    this.secteurList = response.data.data
                })
        },

        addEntreprise() {
            this.load = true
            axios.post(this.$store.state.serveur + 'api/entreprise', {
                nom: this.entrepriseInfo.nom.toString(),
                phone: this.entrepriseInfo.phone.toString(),
                email: this.entrepriseInfo.email.toString(),
                lieu: this.entrepriseInfo.lieu.toString(),
                adresse: this.entrepriseInfo.adresse.toString(),
                status: this.entrepriseInfo.status,
                secteur_id: this.entrepriseInfo.secteurId
            }).then((response) => {
                let entreprise = response.data.data
                this.adminInfo.entrepriseId = entreprise.id
                if (response.data.status == 'true') {
                    axios.post(this.$store.state.serveur + 'api/users', {
                        nom: this.adminInfo.nom.toString(),
                        prenom: this.adminInfo.prenoms.toString(),
                        email: this.entrepriseInfo.email.toString(),
                        phone: this.adminInfo.phone.toString(),
                        password: this.entrepriseInfo.password,
                        entreprise_id: this.adminInfo.entrepriseId,
                        roles_id: [3],
                        operation: []
                    }).then((res) => {
                        this.load = false
                        if (res.data.status == 'true') {
                            Swal.fire({
                                title: 'Success',
                                icon: 'success',
                                text: "Votre entreprise est maintenant sur la plateforme",
                                showConfirmButton: false,
                                timer: 1500
                            }).then(() => {
                                this.$router.go()
                            })
                        }
                    }).catch((err) => {
                        this.load = false
                        console.error(err)
                        Swal.fire({
                            title: 'Oops..',
                            icon: 'error',
                            text: "Cet utilisateur existe déja",
                            showConfirmButton: false,
                            timer: 2500
                        })
                    })
                }
            }).catch((error) => {
                this.load = false
                console.error(error)
                Swal.fire({
                    title: 'Oops..',
                    icon: 'error',
                    text: "Cette entreprise existe déjà",
                    showConfirmButton: false,
                    timer: 2500
                })
            })
        },

        connect() {
            this.load = true
            axios.post(this.$store.state.serveur + 'api/auth/login', {
                email: this.email,
                password: this.password,
            })
                .then((reponse) => {
                    if (reponse.data.access_token) {
                        localStorage.setItem('user', JSON.stringify(reponse.data.user))
                        localStorage.setItem('token', reponse.data.access_token)

                        this.$store.state.user = reponse.data.user
                        this.$store.state.token = reponse.data.access_token

                        let roles = []
                        this.$store.state.user.role.forEach((role) => {
                            roles.push(role.id)
                        })

                        if (roles.includes(3) && !roles.includes(2)) {
                            localStorage.setItem("role", 'admin')
                            this.$store.state.role = 'admin'
                        } else if (roles.includes(2) && !roles.includes(3)) {
                            localStorage.setItem("role", 'user')
                            this.$store.state.role = 'user'
                        } else if (roles.includes(3) && roles.includes(2)) {
                            localStorage.setItem("role", 'admin_user')
                            this.$store.state.role = 'admin_user'
                        }

                        console.log(this.$store.state.role)
                        this.$router.push('/admin/dashboard')
                        this.load = false
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'connexion réussie',
                            showConfirmButton: false,
                            timer: 1500,
                        })

                    } else {
                        this.load = false
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    }
                })
                .catch(error => {
                    this.load = false
                    console.error(error)
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: "Vérifiez vos accès",
                        text:  'L\'addresse email ou le mot de passe est incorrècte.',
                        showConfirmButton: false,
                        timer: 2000,
                    })
                })
        },
    },

    created() {
        // this.$store.state.user = null,
        // this.$store.state.acess_token = ""
        // localStorage.clear()
    },

}


</script>
