<template>

    <div class="iq-top-navbar">
        <div class="container">
            <div class="iq-navbar-custom">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="iq-navbar-logo d-flex align-items-center justify-content-between">
                        <i class="ri-menu-line wrapper-menu"></i>
                        <a href="/" class="header-logo">
                            <img src="/assets/images/pdb.jpg" class="img-fluid rounded-normal light-logo" alt="logo">
                        </a>
                    </div>
                    <div class="iq-menu-horizontal">
                        <nav class="iq-sidebar-menu">
                            <div class="iq-sidebar-logo d-flex align-items-center justify-content-between">
                                <a href="/" class="header-logo">
                                    <img src="/assets/images/logo.png" class="img-fluid rounded-normal" alt="logo">
                                </a>
                                <div class="iq-menu-bt-sidebar">
                                    <i class="las la-bars wrapper-menu"></i>
                                </div>
                            </div>
                            <ul id="iq-sidebar-toggle" class="iq-menu d-flex">
                                <li :class="{active:navHomeActive}">
                                    <router-link to="/"><span>Accueil</span></router-link>
                                </li>
                                <li :class="{active:navConInsActive}">
                                    <router-link to="/conins"><span>Mon Espace</span></router-link>
                                </li>     
                                <li :class="{active:navAboutActive}">
                                    <router-link to="/about"><span>A propos de nous</span></router-link>
                                </li>                            
                            </ul>
                        </nav>
                    </div>
                    <nav class="navbar navbar-expand-lg navbar-light p-0">
                        <div class="change-mode">
                            <div class="custom-control custom-switch custom-switch-icon custom-control-indivne">
                                <div class="custom-switch-inner">
                                    <p class="mb-0"> </p>
                                    <input type="checkbox" class="custom-control-input" id="dark-mode"
                                        data-active="true">
                                    <label class="custom-control-label" for="dark-mode" data-mode="toggle">
                                        <span class="switch-icon-left"><i class="a-left ri-moon-clear-line"></i></span>
                                        <span class="switch-icon-right"><i class="a-right ri-sun-line"></i></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>

</template>


<script>

export default{
    
    name: 'HeadVue',
    props: {
        navHomeActive: {
            type: Boolean,
            required: false,
            default: false
        },
        navConInsActive: {
            type: Boolean,
            required: false,
            default: false
        },
        navAboutActive: {
            type: Boolean,
            required: false,
            default: false
        },
        
    },
}

</script>