<template>
    <HeadVue StatActive="true" />
    <div class="content-page" style="margin-top: 60px;">
        <div class="container-fluid container">
            <br>
            <div class="d-flex">
                <div>
                    <!-- <label for="">De</label> -->
                    <input type="date" name="" v-model="payload.from" class="form-control bg-white" id="">
                </div>
                <div class="col-md-1"></div>
                <div>
                    <!-- <label for="">A</label> -->
                    <input type="date" name="" v-model="payload.to" class="form-control bg-white" id="">
                </div>
                <div class="col-md-1"></div>
                <div>
                    <button type="button" @click="getStats" class="btn btn-primary">Rechercher</button>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-lg-4 col-sm-6" v-for="(stat,index) in stats" :key="index">
                    <div class="card card-block card-stretch card-height blog pricing-details">
                        <div class="card-body border text-center rounded">
                            <div class="pricing-header">
                                <div class="icon-data"><i class="ri-star-line"></i>
                                </div>
                                <h2 class="mb-4 display-5 font-weight-bolder">{{ stat.rateMoy}}<small class="font-size-14 text-muted"></small></h2>
                            </div>
                            <h3 class="mb-3">{{stat.name}}</h3>
                            <ul class="list-unstyled mb-0">
                                <li>Nombre de personne reçu : <strong>{{ stat.personReceivedCount }}</strong> </li>
                                <li>Nombre de retour client : <strong>{{ stat.noteCount }}</strong> </li>
                                <li>Nombre de personne non reçu : <strong>{{ stat.personNotreceived }}</strong></li>
                            </ul> <a href="#" class="btn btn-primary mt-5">Voir plus</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <FootVue />
</template>
<script>

    import axios from 'axios'
    // import Swal from 'sweetalert2'
    import FootVue from '@/components/admin/fixed/FootVue.vue'
    import HeadVue from '@/components/admin/fixed/HeadVue.vue'
        export default {

            name: 'app',
            components: {
                HeadVue,
                FootVue,
            },
            data() {
                return {
                    stats: [],
                    star:0,
                    message:'',
                    payload:{}
                    
                }
            },
            computed: {
                
                
            },
            methods: {
                getSecteur() {
                    this.isLoading =true
                        axios.get(this.$store.state.serveur + 'api/satisfaction-stats')
                        .then(res => {
                            this.stats = res.data.data
                            this.isLoading =false
                        })
                        .catch(err =>{
                            this.isLoading =false
                            console.log(err)
                        })
                },
                rate(n){
                    this.star=n
                    for (let index = 0; index <5; index++) {
                        let ids = "star"+(index+1)
                        document.getElementById(ids).style.color =""
                        
                    }
                    if (this.star <= 2) {
                        for (var i = 0; i < this.star; i++) {
                            let ids = "star"+(i+1)
                            console.log(ids)
                            document.getElementById(ids).style.color ="red"
                        }
                    }
                    if (this.star == 3) {
                        for (var j = 0; j < this.star; j++) {
                            let ids = "star"+(j+1)
                            document.getElementById(ids).style.color ="#CBED5B"
                        }
                    }
                    if (this.star > 3) {
                        for (var k = 0; k < this.star; k++) {
                            let ids = "star"+(k+1)
                            document.getElementById(ids).style.color ="#0FFF50"
                        }
                    }
                    
                },
                getStats(){
                       this.isLoading =true
                        axios.post(this.$store.state.serveur + 'api/satisfaction-stats',{
                            from:this.payload.from,
                            to:this.payload.to
                        })
                        .then(res => {
                            console.log(res)
                            this.stats=  res.data.data
                            this.isLoading =false
                        })
                        .catch(err =>{
                            this.isLoading =false
                            console.log(err)
                        })
                },
            },
            created() {
                this.getSecteur()
                
            }
            
        };

</script>

<style scoped>

</style>