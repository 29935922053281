<template>

    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="card card-block card-stretch card-height">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div class="navbar-breadcrumb d-flex align-items-center">
                                <h1 class="mb-1 "><span class="badge badge-info">Mes Rendez-vous</span></h1>
                            </div>
                        </div>
                        <ul class="nav nav-tabs justify-content-center" id="myTab-2" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="op1-tab-justify" data-toggle="tab" href="#op1-justify"
                                    role="tab" aria-controls="op1" aria-selected="true">Mes
                                    Rendez-vous à traiter</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="op2-tab-justify" data-toggle="tab" href="#op2-justify"
                                    role="tab" aria-controls="op2" aria-selected="false">Mes Rendez-vous traités</a>
                            </li>
                        </ul>

                        <div class="tab-content" id="myTabContent-3">
                            <!--Toute les RDVS-->
                            <div class="tab-pane fade show active" id="op1-justify" role="tabpanel"
                                aria-labelledby="op1-tab-justify">
                                <div class="card-header d-flex justify-content-between">
                                    <div class="iq-header-title">

                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive data-table">
                                        <div>
                                            <h5>Nombre total: {{ listRdvOperateur.length }} </h5>
                                        </div>
                                        <table class="table" id="table_operateur" style="width:100%">
                                            <thead>
                                                <tr>
                                                    <th>Specialité</th>
                                                    <th>Nom&Prénoms</th>
                                                    <th>Email&Contact</th>
                                                    <th>Date&Heure</th>
                                                    <th>Code RDV</th>
                                                    <th>Options</th>
                                                </tr>
                                            </thead>
                                            <LoaderVue margin="mt-5" v-if="load" />
                                            <tbody>
                                                <tr v-for="rdv in listRdvOperateur" :key="rdv.id">
                                                    <td> {{ rdv.operation.libelle }} </td>
                                                    <td> {{ rdv.nom }} <br> {{ rdv.prenom }} </td>
                                                    <td> {{ rdv.email }} <br> {{ rdv.phone }} </td>
                                                    <td> {{ rdv.date }} <br> {{ rdv.heure }} </td>
                                                    <td> {{ rdv.code }} </td>
                                                    <td>
                                                        <div 
                                                            class="d-flex align-items-center list-action">
                                                        </div>
                                                        <div class="dropdown">
                                                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i class="ri-drag-drop-line"></i>
                                                            </button>
                                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                <a class="dropdown-item" @click="treatRdv(rdv.id,1)"><i class="ri-edit-box-line"></i>Traiter</a>
                                                                <a class="dropdown-item" @click="treatRdv(rdv.id,3)"><i class="ri-arrow-left-right-line"></i>Permuter</a>
                                                                <a class="dropdown-item" @click="treatRdv(rdv.id,2)"><i class="ri-close-circle-line"></i>Annuler</a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <!--RDVS traités-->
                            <div class="tab-pane fade" id="op2-justify" role="tabpanel"
                                aria-labelledby="op2-tab-justify">
                                <div class="card-header d-flex justify-content-between">
                                    <div class="iq-header-title">

                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive data-table">
                                        <div>
                                            <h5>Nombre total: {{ listRdvOperateurTraite.length }} </h5>
                                        </div>
                                        <table class="table" id="table_operateur_traite" style="width:100%">
                                            <thead>
                                                <tr>
                                                    <th>Specialité</th>
                                                    <th>Nom&Prénoms</th>
                                                    <th>Email&Contact</th>
                                                    <th>Date&Heure</th>
                                                    <th>Code RDV</th>
                                                    <th>rapport</th>
                                                </tr>
                                            </thead>
                                            <LoaderVue margin="mt-5" v-if="load" />
                                            <tbody>
                                                <tr v-for="rdv in listRdvOperateurTraite" :key="rdv.id">
                                                    <td> {{ rdv.operation.libelle }} </td>
                                                    <td> {{ rdv.nom }} <br> {{ rdv.prenom }} </td>
                                                    <td> {{ rdv.email }} <br> {{ rdv.phone }} </td>
                                                    <td> {{ rdv.date.slice(0, 10) }} <br> {{ rdv.heure }} </td>
                                                    <td> {{ rdv.code }} </td>
                                                    <td class="text-center"> <button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#exampleModalLong" @click="report = rdv"><i class="ri-information-line"></i></button>  </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="vld-parent">
        <loading v-model:active="isLoading" can-cancel="false" is-full-page="true" />
    </div>

</template>

<script>

import axios from 'axios'
import Swal from 'sweetalert2'
import LoaderVue from './Loader.vue'

import $ from "jquery"

export default {
    name: 'OperateurComponent',

    components: {
        LoaderVue,
    },

    data() {
        return {
            load: false,
            isLoading: false,
            listRdvOperateur: [],
            listRdvOperateurTraite: [],
            report:null
        }
    },


    methods: {

        applyDataTable(id) {
            $(document).ready(function () {
                $(id).DataTable(
                    {
                        "lengthMenu": [ 5,10, 25, 50, 75, 100 ],
                        "pageLength": 10,
                        order: [[3, 'asc']],
                        stateSave: true,
                        "bDestroy": true
                    }
                );
            })
        },

        getAllOpeRdv() {
            this.listRdvOperateur = []
            this.load = true
            axios.get(this.$store.state.serveur + 'api/getfu')
                .then((response) => {
                    this.load = false
                    console.log('dndnd',response.data)
                    this.applyDataTable("#table_operateur")
                    this.listRdvOperateur = response.data.data
                }).catch((error) => {
                    console.error(error)
                })
        },

        getAllOpeRdvTraite() {
            this.listRdvOperateurTraite = []
            axios.get(this.$store.state.serveur + 'api/getfut')
                .then((response) => {
                    
                    this.applyDataTable("#table_operateur_traite")
                    this.listRdvOperateurTraite = response.data.data
                }).catch((error) => {
                    console.error(error)
                })
        },

         treatRdv(rdv_id,ID) {
            let app = this
            Swal.fire({
                title: 'Confirmer-vous cette action ?',
                showDenyButton: true,
                denyButtonText: 'Non',
                confirmButtonText: 'Oui',
                input: 'textarea',
                inputLabel: 'Message',
                inputPlaceholder: 'Ecrivez ici ...',
                inputAttributes: {
                    'aria-label': 'Ecrivez ici...',
                    'id': 'message',
                },
            }).then((value) => {
                console.log(value)
                var element = document.getElementById('message').value;
                console.log(element,value)
                if (value.isConfirmed) {
                    
                                app.isLoading = true
                                axios.post(this.$store.state.serveur + 'api/update/' + rdv_id, {
                                    status: ID,
                                    resume:value.value
                                }).then((response) => {
                                        
                                    app.getAllOpeRdv()
                                    app.getAllOpeRdvTraite()
                                    app.isLoading = false
                                    console.log(response)
                                    if (response.data.status == 'true') {
                                        
                                        Swal.fire({
                                            title: 'Action effectué avec succès',
                                            icon: 'success',
                                            timer: 1500
                                        })
                                    }
                                }).catch((error) => {
                                    console.log(error)
                                    app.isLoading = false
                                    Swal.fire({
                                        title: 'Oops',
                                        icon: 'error',
                                        text: 'erreur'
                                    })
                                })
                        
                    
                }
            })
        },

        rdvExpired(dt){
            const now = new Date()
            const date = new Date(dt)

            const toReturn = now > date ? true : false
            return toReturn
        }
    },

    created() {
        this.getAllOpeRdv()
        this.getAllOpeRdvTraite()
    }
}


</script>